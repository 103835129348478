import React from 'react';
import { useToggle } from 'react-use';

import { useExperiment } from '@mssgme/ui';
// import { BrandingModal } from './BrandingModal';
import { BrandingMedium, BrandingSticky, BrandingMinimalist } from './Variants';

const ExperimentsMap = {
    minimalist: BrandingMinimalist,
    medium: BrandingMedium,
    sticky: BrandingSticky,
};

export function BrandingBottom(props) {
    const [modalVisible, toggleModal] = useToggle(false);
    const expId = useExperiment({ id: 'branding' });
    const Tag = ExperimentsMap[expId] || BrandingMinimalist;

    return (
        <>
            {Tag && <Tag onModal={toggleModal} {...props} />}

            {/*{modalVisible && <BrandingModal onClose={toggleModal} />}*/}
        </>
    );
}

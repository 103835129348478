import { compile } from 'path-to-regexp';
import { EventEmitter } from 'events';

import { isJsonContentType, JSON_CONTENT_TYPE, normalizeUrl } from './helpers';
import {
    ValidationError,
    RequestError,
    LimitRequestsError,
    AuthorizationError,
    ForbiddenError,
    NotFoundError,
    NetworkError,
} from './errors';
import { preparePayload, setContentType } from './middlewares';

export class ApiRequest {
    constructor({ method, url, payload, headers = {} }) {
        this.method = method;
        this.url = url;
        this.payload = payload;
        this.headers = headers;
    }
}

export class ApiClient extends EventEmitter {
    constructor({ baseUrl = '/api', headers, middlewares = [setContentType, preparePayload] }) {
        super();
        this._baseUrl = normalizeUrl(baseUrl);
        this._headers = { Accept: JSON_CONTENT_TYPE, ...headers };
        this._params = {};
        this._middlewares = middlewares;

        this.addMiddleware(this.addHeaders);
    }

    addMiddleware(fn) {
        this._middlewares.push(fn);
    }

    /**
     * @param {{ headers: object }} request Request object to modify
     * @private
     */
    addHeaders(request) {
        request.headers['Accept-Language'] = navigator.language;
    }

    param(...args) {
        if (args.length === 0) {
            throw new Error('key argument is required');
        }

        if (args.length === 1) {
            return this._params[args[0]];
        }

        const [key, value] = args;

        if (value == null) {
            delete this._params[key];
        } else {
            this._params[key] = value;
        }
    }

    /**
     * @param {object} param0 Options
     * @param {string} param0.endpoint Endpoint URI pattern
     * @param {object} [param0.params] Optional endpoint parameters
     * @param {object} [param0.query] Optional search query
     * @returns {URL} Resulting URL
     */
    generateUrl({ endpoint, params = {}, query = {} }) {
        try {
            const toPath = compile(endpoint);
            const compiledPath = toPath({ ...this._params, ...params });
            const url = new URL(this._baseUrl + compiledPath);

            for (const [key, value] of Object.entries(query)) {
                if (value && typeof value === 'object') {
                    for (const [vKey, vValue] of Object.entries(value)) {
                        url.searchParams.append(`${key}[${vKey}]`, vValue);
                    }
                } else {
                    url.searchParams.append(key, value);
                }
            }

            return url;
        } catch (e) {
            throw new Error(`Failed to generate URL for endpoint "${endpoint}": ${e.message}`);
        }
    }

    /**
     *
     * @param {'GET'|'POST'|'PUT'|'DELETE'|'OPTIONS'} method Request method
     * @param {URL} url Request URL
     * @param {object} [payload] Request payload
     * @returns {ApiRequest} Resulting request descriptor
     */
    createApiRequest(method, url, payload) {
        const request = new ApiRequest({
            method: method.toUpperCase(),
            url,
            payload,
            headers: { ...this._headers },
        });

        this._middlewares.forEach((fn) => {
            fn.call(this, request);
        });

        return request;
    }

    /**
     * @param {ApiRequest} request Request descriptor
     * @param {boolean} [raw=false] Return raw response object
     * @returns {Promise<object|string>} Response result
     */
    async execRequest(request, raw) {
        let response;

        try {
            response = await fetch(request.url, {
                credentials: 'same-origin',
                mode: 'cors',
                method: request.method,
                body: request.payload,
                headers: request.headers,
            });
        } catch (e) {
            throw new NetworkError(`${e.message}\n\tat "${request.url}"`);
        }

        const isJson = isJsonContentType(response.headers);

        if (response.ok) {
            if (isJson) {
                return await response.json();
            }

            return raw ? response : await response.text();
        }

        const json = isJson ? await response.json() : undefined;

        switch (response.status) {
            case 400:
            case 422:
                throw new ValidationError({ url: response.url.toString(), status: response.status, json });
            case 401:
                throw new AuthorizationError({ url: response.url.toString(), status: response.status, json });
            case 403:
                throw new ForbiddenError({ url: response.url.toString(), status: response.status, json });
            case 404:
                throw new NotFoundError({ url: response.url.toString(), status: response.status, json });
            case 429:
                throw new LimitRequestsError({ url: response.url.toString(), status: response.status, json });
            default:
                throw new RequestError({
                    url: response.url.toString(),
                    status: response.status,
                    message: response.statusText,
                    json,
                });
        }
    }

    /**
     * @param {'GET'|'POST'|'PUT'|'DELETE'|'OPTIONS'} method Request method
     * @param {string} endpoint Endpoint pattern
     * @param {{ payload?: object; query?: object; raw?: boolean } | object} [opts] Optional endpoint options
     * @returns {Promise<object|string>} Call result
     */
    async apiCall(method, endpoint, opts = {}) {
        const { raw, payload, query, ...params } = opts;
        const url = this.generateUrl({ endpoint, params, query });
        const request = this.createApiRequest(method, url, payload);

        return this.execRequest(request, raw);
    }

    /**
     * @param {'GET'|'POST'|'PUT'|'DELETE'|'OPTIONS'} method Request method
     * @param {string} endpoint Endpoint pattern
     * @returns {(opts: { payload?: object; query?: object; raw?: boolean } | object) => Promise<object|string>} Callback
     */
    method(method, endpoint) {
        return (opts) => this.apiCall(method, endpoint, opts);
    }

    get(endpoint) {
        return this.method('GET', endpoint);
    }

    put(endpoint) {
        return this.method('PUT', endpoint);
    }

    patch(endpoint) {
        return this.method('PATCH', endpoint);
    }

    post(endpoint) {
        return this.method('POST', endpoint);
    }

    delete(endpoint) {
        return this.method('DELETE', endpoint);
    }
}

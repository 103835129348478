import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { motion, startAnimation, useMotionValue, useTransform } from 'framer-motion';
import { FiX } from 'react-icons/fi';

import { cls } from '@mssgme/helpers';
import { renderNode, usePresenceRemoval } from '@mssgme/ui';

import { usePageTheme } from '../../hooks';

import styles from './ThemedModal.scss';

const stopPropagation = (e) => {
    e.stopPropagation();
};

const animateValue = (from, to, transition) => {
    void startAnimation('', from, to, transition);

    return {
        stop: () => from.stop(),
    };
};

export const ThemedModal = ({
    handleHash,
    compact,
    overlay,
    padding,
    children,
    showHeader,
    title,
    onClose,
    onClosing,
    noEscape,
    noop,
    ...rest
}) => {
    const targetNode = document.querySelector('#page_root, #mainRoot');
    const history = useHistory();
    // isDesktopAdaptation rely on main container width and not viewport breakpoint
    // because of mobile/desktop preview in admin panel
    const isDesktopAdaptation = targetNode?.clientWidth > 600;
    const revealOffsetMap = isDesktopAdaptation && compact ? [40, 0] : ['100%', '0%'];
    const opacity = useMotionValue(0);
    const y = useTransform(opacity, [0, 1], revealOffsetMap);
    const scale = useTransform(opacity, [0, 1], [0.95, 1]);
    const { theme } = usePageTheme();
    const style = useMemo(
        () => ({
            container: {
                y,
                backgroundColor: theme.colors.background,
                color: theme.colors.text,
                opacity: isDesktopAdaptation && compact ? opacity : 1,
                scale: isDesktopAdaptation && compact ? scale : 1,
                fontFamily: theme.page.font?.family || 'inherit',
            },
            close: { color: theme.colors.text },
        }),
        [theme.colors]
    );
    const [isClosing, setClosing] = useState(true);

    const handleClose = useCallback(
        async (...args) => {
            if (isClosing || noEscape) {
                return;
            }

            setClosing(true);

            if (handleHash) {
                history.replace(history.location.pathname);
            }

            if (onClosing && false === (await onClosing())) {
                return;
            }

            animateValue(opacity, 0, {
                duration: 0.3,
                type: 'ease',
                onComplete: () => {
                    if (!onClose) {
                        return;
                    }

                    if (onClose === true) {
                        history.goBack();
                    } else if (typeof onClose === 'string') {
                        history.push(onClose);
                    } else {
                        onClose(...args);
                    }
                },
            });
        },
        [isClosing, onClose, onClosing, handleHash, noEscape]
    );

    useEffect(() => {
        const controls = animateValue(opacity, 1, {
            duration: 0.3,
            type: 'ease',
            onComplete: () => setClosing(false),
        });

        return controls.stop;
    }, []);

    usePresenceRemoval();

    if (!targetNode) {
        return null;
    }

    return ReactDom.createPortal(
        <div className={styles.rootContainer} onClick={noop && stopPropagation}>
            {(compact || overlay) && (
                <motion.div
                    style={{ opacity }}
                    className={styles.overlay}
                    onClick={handleClose}
                />
            )}

            <motion.div
                className={cn(styles.root, compact && styles.compact, isDesktopAdaptation && styles.desktop)}
                style={style.container}
            >
                {(showHeader || title) && (
                    <div className={styles.header}>
                        <div className={styles.closeButton} style={style.close} onClick={handleClose}>
                            <FiX size={24} />
                        </div>
                        {title && <div className={styles.title}>{title}</div>}
                        <div />
                    </div>
                )}

                <div {...cls([styles.content, padding && styles.padded], rest)}>
                    {renderNode(children, handleClose)}
                </div>
            </motion.div>
        </div>,
        targetNode
    );
};

ThemedModal.propTypes = {
    showHeader: PropTypes.bool,
    title: PropTypes.any,
    onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.bool]).isRequired,
    onClosing: PropTypes.func,
    theme: PropTypes.shape({ backgroundColor: PropTypes.string, color: PropTypes.string }),
    compact: PropTypes.bool,
    handleHash: PropTypes.bool,
    overlay: PropTypes.bool,
    fullWidth: PropTypes.bool,
    padding: PropTypes.bool,
    noEscape: PropTypes.bool,
    noop: PropTypes.bool,
};

ThemedModal.defaultProps = {
    showHeader: true,
    noEscape: false,
};

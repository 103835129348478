import { useMemo } from 'react';
import { Link as NavLink } from 'react-router-dom';

import { LinkBlank } from '@mssgme/ui';
import { isMatchedById } from '@mssgme/shared';
import { getPageName } from '../lib';

import { useCoreContext, useWebsiteData } from '../../hooks';

const generateUrl = (value, type) => {
    if (!value) {
        return '';
    }

    switch(type) {
        case 'tel':
            return 'tel:' + value;
        case 'email':
            return 'mailto:' + value;
        case 'sms':
            return 'sms:' + value;
        default:
            return value;
    }
};

const getTag = (actionType) => {
    switch(actionType) {
        case 'fullImage':
        case 'nothing':
            return 'div';
        case 'page':
            return NavLink;
        default:
            return LinkBlank;
    }
};

const getPageMeta = (pageId, isViewMode, website) => {
    const page = pageId && website.pages.find(isMatchedById(pageId));

    if (!page) {
        return {};
    }

    const isHomePage = website.homePageId === page._id;
    const slug = isHomePage ? '' : page.slug;

    return {
        _id: page._id,
        title: page.title,
        name: getPageName(page, isHomePage) || page.slug,
        url: website.finalUrl + (slug ? '/' + slug : '') + (page.draft ? `?draft=${page.draft}` : ''),
        slug: isViewMode ? pageId : slug,
        type: 'page',
    };
};

export const useAction = ({ action, isViewMode, interactive }) => {
    const website = useWebsiteData();
    const { router } = useCoreContext();

    return useMemo(
        () => {
            const { title, name, url, slug, _id, type } = getPageMeta(action?.page, isViewMode, website);
            const isInternal = type === 'page' && !!_id;
            const currentUrl = isInternal ? url : generateUrl(action.url, action.type);
            const Tag = interactive ? getTag(action.type) : 'div';
            const tagAttributes = interactive ? isInternal ? { to: router.toPage(slug) } : { href: currentUrl } : {};

            return {
                pageMeta: { title, name },
                isInternal,
                currentUrl,
                Tag,
                tagAttributes,
            };
        },
        [action, isViewMode, interactive, website]
    );
};

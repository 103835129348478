import cn from 'classnames';
import React, { useMemo } from 'react';

import { cls } from '@mssgme/helpers';
import { SocialsBlockKind } from '@mssgme/shared';
import { LandingsPropTypes, socialNameForUrl } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { Glyph } from '../../../UI';
import { BlockBase } from '../BlockBase';

import styles from './Socials.scss';

const sizesMap = {
    small: 18,
    medium: 24,
    large: 30,
};

const alignmentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

export default function Socials({ block, interactive, style: baseStyle, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const combined = useMemo(() => (baseStyle ? { ...style, ...baseStyle } : style), [style, baseStyle]);
    const items = useMemo(() => {
        if (block.items.length) {
            return block.items.map(({ url }) => ({
                url,
                glyph: { type: 'icon', icon: socialNameForUrl(url) },
            }));
        }

        return [
            { glyph: { type: 'icon', icon: 'facebook' } },
            { glyph: { type: 'icon', icon: 'twitch' } },
            { glyph: { type: 'icon', icon: 'instagram' } },
            { glyph: { type: 'icon', icon: 'twitter' } },
            { glyph: { type: 'icon', icon: 'pinterest' } },
            { glyph: { type: 'icon', icon: 'tiktok' } },
        ];
    }, [block.items]);

    return (
        <BlockBase style={combined} {...cls(styles.root, rest)}>
            <ul
                className={cn({ [styles.list]: true, [styles[theme.alignment]]: true })}
                style={{ justifyContent: alignmentMap[theme.alignment] }}
            >
                {items.map(({ url, glyph }, index) => {
                    const Tag = interactive && url ? 'a' : 'div';

                    return (
                        <li key={index}>
                            <Tag
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: block.items.length ? theme.color : '#999' }}
                            >
                                <Glyph size={sizesMap[theme.size]} glyph={glyph} />
                            </Tag>
                        </li>
                    );
                })}
            </ul>
        </BlockBase>
    );
}

Socials.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Socials.defaultProps = BlockBase.defaultProps;

Socials.kind = SocialsBlockKind;

import cn from 'classnames';
import React from 'react';

import { __t, QuoteBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Quote.scss';

export default function Quote({ block, ...rest }) {
    const { theme, style } = useBlockTheme(block);

    return (
        <BlockBase {...cls(styles.root, rest)}>
            <div
                className={cn(styles.content, styles[theme.template])}
                style={{ ...style, borderLeftColor: theme.color }}
            >
                {block.content || __t('lorem')}
            </div>
            {block.author && (
                <p className={styles.author} style={{ color: style.color, fontSize: `calc(${theme.fontSize} * 0.9)` }}>
                    - {block.author}
                </p>
            )}
        </BlockBase>
    );
}

Quote.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Quote.defaultProps = BlockBase.defaultProps;

Quote.kind = QuoteBlockKind;

import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStore } from 'react-redux';

import { cls } from '@mssgme/helpers';
import { useElementStyle } from '@mssgme/hooks';
import { InnerHTMLWithScripts, LandingsPropTypes, ThemeContext } from '@mssgme/ui';
import { PageDataContext, RendererContext, WebsiteDataContext } from '../../../contexts';
import { useHomePageData, useResolvedTheme, useScrollTo } from '../../hooks';
import { BuyerActions, CoreContextProvider, Insets } from '../../UI';
import { BLOCKS_MAP, PageProductsRouting } from '../PageBlocks';
import { BackgroundImage } from '../BackgroundImage';
import { BrandingBottom } from '../BrandingBottom';
import { PageMenu } from '../PageMenu';

import styles from './Page.scss';

export const Page = React.forwardRef(function Page(
    { website, page, interactive, embedded, contentClass, footer, header, ...rest },
    innerRef
) {
    const store = useStore();
    const homePage = useHomePageData(website);
    const blocks = useMemo(() => page.blocks.filter((block) => block.enabled && !block.nested), [page.blocks]);
    const [contentRef, scrollTo] = useScrollTo();
    const { theme, style, resolvedTheme, align } = useResolvedTheme(homePage);
    const pageId = page._id;
    const isHomePage = homePage._id === pageId;
    const pagePath = isHomePage ? '' : '/:pageSlug';
    const pageSlug = isHomePage ? '' : page.slug;

    const renderBlock = useMemo(
        // eslint-disable-next-line react/display-name
        () => (block, index) => {
            const Component = BLOCKS_MAP[block.kind];
            const insets = Insets.computeComponentInsets(blocks, block, index, resolvedTheme);
            const key = block._id || `${block.kind + index}`;

            return Component ? (
                <Component
                    key={key}
                    insets={insets}
                    block={block}
                    interactive={interactive}
                    data-block={block._id}
                    data-block-kind={block.kind}
                />
            ) : (
                <div key={key} data-block={block._id} data-block-kind={block.kind}>
                    Unknown block kind &quot;{block.kind}&quot;
                </div>
            );
        },
        [interactive, blocks, resolvedTheme]
    );

    useElementStyle(document.body, !embedded && style);

    useEffect(() => void scrollTo('top'), [pageId]);

    const contents = (
        <div id="page_root" ref={innerRef} style={style} {...cls(styles.root, rest)}>
            {theme.backgroundImage && (
                <BackgroundImage
                    path={theme.backgroundImage}
                    opacity={theme.backgroundImageOpacity}
                />
            )}
            <div ref={contentRef} className={styles.rootInner} id="page_root_inner">
                <div className={cn(styles.content, contentClass)} style={align.container}>
                    {header}

                    <div id="blocks-container" style={align.inner}>
                        {blocks.map(renderBlock)}
                    </div>

                    {website.embeddedCode?.onLoad && website.embeddedCode.content && (
                        <InnerHTMLWithScripts html={website.embeddedCode.content}/>
                    )}

                    {website.branding && <BrandingBottom resolvedTheme={resolvedTheme} />}

                    {footer}
                </div>
            </div>

            <div className={styles.overlayContainer}>
                <div className={styles.overlayContainerContent}>
                    {!embedded && <BuyerActions />}
                    <PageMenu
                        website={website}
                        page={page}
                        interactive={interactive}
                    />
                </div>
            </div>

            <React.Suspense fallback={null}>
                <Switch>
                    <Route path={`${pagePath}/:any(p|c|orders)`}>
                        <PageProductsRouting base={pagePath || '/'} />
                    </Route>
                </Switch>
            </React.Suspense>
        </div>
    );

    return (
        <WebsiteDataContext.Provider value={website}>
            <PageDataContext.Provider value={page}>
                <ThemeContext.Provider value={resolvedTheme}>
                    <RendererContext.Provider value={renderBlock}>
                        <CoreContextProvider store={store} pageSlug={pageSlug}>
                            {contents}
                        </CoreContextProvider>
                    </RendererContext.Provider>
                </ThemeContext.Provider>
            </PageDataContext.Provider>
        </WebsiteDataContext.Provider>
    );
});

Page.propTypes = {
    website: LandingsPropTypes.website.isRequired,
    page: LandingsPropTypes.page.isRequired,
    interactive: PropTypes.bool,
    embedded: PropTypes.bool,
    contentClass: PropTypes.string,
    header: PropTypes.node,
    footer: PropTypes.node,
};

Page.defaultProps = {
    interactive: true,
};

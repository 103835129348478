import PropTypes from 'prop-types';
import React from 'react';

import { Glyph } from '../../../UI';

import styles from './Features.scss';

export const FeaturesItem = ({ item, ...rest }) => {
    return (
        <div className={styles.item} {...rest}>
            <Glyph glyph={item.glyph} size={24} className={styles.icon} />

            <div className={styles.wrapper}>
                <div className={styles.title}>{item.title}</div>

                <section className={styles.content}>{item.content}</section>
            </div>
        </div>
    );
};

FeaturesItem.propTypes = {
    item: PropTypes.object.isRequired,
};

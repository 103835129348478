import PropTypes from 'prop-types';
import React from 'react';

import { __t } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import styles from './HatAvatar.scss';

export default function HatAvatar({ logoSrc, textColor, title, subtitle, align, ...rest }) {
    return (
        <div {...cls([styles.root, styles[align]], rest)}>
            <div className={styles.logoBox} style={{ backgroundColor: logoSrc ? 'transparent' : '#ccc' }}>
                {logoSrc && <img src={logoSrc} alt="Logo" />}
            </div>
            <div className={styles.content} style={{ color: textColor }}>
                <h1>{title ? title : __t('header')}</h1>
                {subtitle || title ? subtitle ? <p>{subtitle}</p> : null : <p>{__t('subheader')}</p>}
            </div>
        </div>
    );
}

HatAvatar.propTypes = {
    logoSrc: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    align: PropTypes.oneOf(['left', 'right', 'center']),
};

HatAvatar.defaultProps = {
    align: 'center',
    logoSrc: '',
    textColor: '#333333ff',
    title: __t('header'),
    subtitle: __t('subheader'),
};

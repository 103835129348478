import { useMemo } from 'react';

export const useWebsiteMenu = (website, menuItems) => {
    const enabled = !!website.menu?.enabled;
    const homePageId = website.homePageId;
    const items = menuItems || website.menu?.items;

    return useMemo(
        () => {
            if (!items) {
                return {
                    enabled,
                    patch: false,
                    items: [],
                };
            }

            const ids = website.pages.map((page) => page._id);
            const result = items.filter(({ page }) => page === homePageId);
            let patch = false;

            for (const item of items) {
                if (item.page === homePageId) {
                    continue;
                }

                if (ids.includes(item.page)) {
                    result.push(item);
                } else {
                    patch = true;
                }
            }

            return {
                enabled,
                patch,
                items: result,
            };
        },
        [website.pages, items, homePageId, enabled]
    );
};

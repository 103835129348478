import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Notification from './Notification';
import styles from './NotificationsBag.scss';
import useNotificationCenter from './useNotificationCenter';

const propTypes = {
    notificationComponent: PropTypes.elementType,
    className: PropTypes.string,
};

const defaultProps = {
    notificationComponent: Notification,
};

function NotificationsBag({ className, notificationComponent: NotificationComponent }) {
    const notificationCenter = useNotificationCenter();

    const content = (
        <div className={classNames(styles.root, className)}>
            {notificationCenter.getNotifications().map((notification) => (
                <NotificationComponent
                    key={notification.id}
                    onClose={() => notificationCenter.remove(notification.id)}
                    {...notification.options}
                >
                    {notification.message}
                </NotificationComponent>
            ))}
        </div>
    );

    return ReactDOM.createPortal(content, document.body);
}

NotificationsBag.propTypes = propTypes;
NotificationsBag.defaultProps = defaultProps;

export default NotificationsBag;

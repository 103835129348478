import cn from 'classnames';
import React, { useMemo } from 'react';

import { LandingsPropTypes } from '@mssgme/ui';
import { cls } from '@mssgme/helpers';
import { DividerBlockKind } from '@mssgme/shared';
import { useBlockTheme } from '../../../hooks';
import { BlockBase } from '../BlockBase';

import styles from './Divider.scss';

export default function Divider({ block, insets, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const resolvedInsets = useMemo(() => {
        let vertical = 16;
        const horizontal = 16;

        switch (theme.margin) {
            case 'large':
                vertical = vertical * 2;
                break;

            case 'small':
                vertical = ~~(vertical / 2);
                break;

            default:
        }

        return BlockBase.resolveInsets({
            padding: { top: vertical, bottom: vertical, left: horizontal, right: horizontal },
        });
    }, [theme.margin]);

    return (
        <BlockBase insets={insets} {...cls([styles.root, styles[theme.template]], rest)}>
            <div style={resolvedInsets}>
                {theme.template === 'line' && (
                    <div className={cn(styles.divider, styles[theme.alignment])} style={style} />
                )}
                {theme.template === 'dots' && (
                    <div className={cn(styles.divider, styles[theme.size], styles[theme.alignment])}>
                        <div style={{ backgroundColor: style.backgroundColor }} />
                        <div style={{ backgroundColor: style.backgroundColor }} />
                        <div style={{ backgroundColor: style.backgroundColor }} />
                    </div>
                )}
            </div>
        </BlockBase>
    );
}

Divider.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Divider.defaultProps = {
    ...BlockBase.defaultProps,
    insets: { margin: { top: -16, bottom: -16, left: 0, right: 0 }, padding: 0 },
};

Divider.kind = DividerBlockKind;

import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { __t } from '@mssgme/shared';
import Cover from '../Cover/Cover';
import styles from './HatBrand.scss';

export default function HatBrand({ logoSrc, textColor, title, subtitle, align, avatarBorderColor, ...rest }) {
    return (
        <div>
            <Cover {...rest} />

            <div className={cn(styles.panelWrapper, styles.withCover, styles[align])}>
                <div
                    className={styles.logoBox}
                    style={{ backgroundColor: logoSrc ? avatarBorderColor : '#ccc', borderColor: avatarBorderColor }}
                >
                    {logoSrc && <img src={logoSrc} alt="Logo" />}
                </div>
                <div className={styles.content} style={{ color: textColor }}>
                    <h1>{title ? title : __t('header')}</h1>
                    {subtitle || title ? subtitle ? <p>{subtitle}</p> : null : <p>{__t('subheader')}</p>}
                </div>
            </div>
        </div>
    );
}

HatBrand.propTypes = {
    blockStyles: PropTypes.object.isRequired,
    hasPadding: PropTypes.bool,
    coverSrc: PropTypes.string,
    logoSrc: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    avatarBorderColor: PropTypes.string,
    isMobileMode: PropTypes.bool,
};

HatBrand.defaultProps = {
    hasPadding: true,
    coverSrc: '',
    logoSrc: '',
    textColor: '#333333ff',
    avatarBorderColor: '#fff',
    title: __t('header'),
    subtitle: __t('subheader'),
    align: 'center',
};

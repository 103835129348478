import React from 'react';

import { MessengersBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme } from '../../../hooks';
import { BlockBase, useBlockHorizontalInsetsPadding } from '../BlockBase';
import MessengerButton from './MessengerButton';

import styles from './Messengers.scss';

export default function Messengers({ block, insets, interactive, ...rest }) {
    const { theme, style } = useBlockTheme(block);
    const messengers = block.messengers.filter((messenger) => messenger.enabled);
    const count = messengers.length;
    const shouldSqueeze = theme.messengersMode === 'beefy';
    const blockInsets = useBlockHorizontalInsetsPadding(insets, theme.padding);

    return (
        <BlockBase
            insets={blockInsets}
            {...cls([styles.root, styles[`${theme.messengersMode}Mode`], shouldSqueeze && styles.squeeze], rest)}
        >
            {count > 0 && (
                <ul className={styles.list} data-count={count}>
                    {messengers.map((messenger, index) => (
                        <React.Fragment key={messenger.platform + index}>
                            {index > 0 && <li className={styles.break} />}
                            <li key={messenger.platform + index}>
                                <MessengerButton
                                    count={count}
                                    index={index}
                                    messenger={messenger}
                                    interactive={interactive}
                                    theme={theme}
                                    style={style}
                                />
                            </li>
                        </React.Fragment>
                    ))}
                </ul>
            )}
            {messengers.length === 0 && (
                <div className={styles.placeholder} data-count={messengers.length}>
                    <div style={style} />
                    <div style={style} />
                    <div style={style} />
                    <div style={style} />
                </div>
            )}
        </BlockBase>
    );
}

Messengers.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
};

Messengers.defaultProps = BlockBase.defaultProps;

Messengers.kind = MessengersBlockKind;

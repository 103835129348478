import React from 'react';
import PropTypes from 'prop-types';

import { __t } from '@mssgme/shared';
import { useBrandingUrl } from '@mssgme/hooks';
import { LinkBlank } from '@mssgme/ui';
import { MssgmeLogoIcon } from '@mssgme/icons';

import styles from './BrandingSticky.scss';

export function BrandingSticky({ onModal, ...rest }) {
    const brandingUrl = useBrandingUrl({ target: 'all' });

    return (
        <div className={styles.root} {...rest}>
            <div className={styles.brandingLink}>
                <LinkBlank
                    id="userpage_branding_sticky"
                    data-experiment="sticky"
                    href={brandingUrl}
                    // onClick={onModal}
                >
                    <div className={styles.description}>
                        {__t('made_with')}
                        <div className={styles.logo}>
                            <MssgmeLogoIcon />
                        </div>
                        <span>mssg.me</span>
                    </div>
                </LinkBlank>
            </div>
        </div>
    );
}

BrandingSticky.propTypes = {
    onModal: PropTypes.func,
};

import PropTypes from 'prop-types';
import React from 'react';

import { __t } from '@mssgme/shared';
import Cover from '../Cover/Cover';
import styles from './HatCentered.scss';

export default function HatCentered({ logoSrc, textColor, title, subtitle, avatarBorderColor, ...rest }) {
    const style = rest.hasPadding
        ? {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomLeftRadius: '100% 80%',
            borderBottomRightRadius: '100% 80%',
        }
        : {
            borderBottomLeftRadius: '100% 80%',
            borderBottomRightRadius: '100% 80%',
        };

    return (
        <div>
            <Cover style={style} {...rest} />

            <div
                className={styles.logoBox}
                style={{ backgroundColor: logoSrc ? 'transparent' : '#ccc', borderColor: avatarBorderColor }}
            >
                {logoSrc && <img src={logoSrc} alt="Logo" />}
            </div>
            <div className={styles.content} style={{ color: textColor }}>
                <h1>{title ? title : __t('header')}</h1>
                {subtitle || title ? subtitle ? <p>{subtitle}</p> : null : <p>{__t('subheader')}</p>}
            </div>
        </div>
    );
}

HatCentered.propTypes = {
    blockStyles: PropTypes.object.isRequired,
    hasPadding: PropTypes.bool,
    coverSrc: PropTypes.string,
    logoSrc: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    avatarBorderColor: PropTypes.string,
    isMobileMode: PropTypes.bool,
};

HatCentered.defaultProps = {
    hasPadding: true,
    coverSrc: '',
    logoSrc: '',
    textColor: '#333333ff',
    avatarBorderColor: '#fff',
    title: __t('header'),
    subtitle: __t('subheader'),
};

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { trans } from '@mssgme/shared';
import { SplashScreen } from '@mssgme/ui';
import { Website, configureStore } from '@mssgme/userpage-core';

trans.extend(window.LOCALES);

const data = window.INITIAL_DATA;
const basename = window.BASENAME;
const lang = window.document.documentElement.lang;
const store = configureStore(data);

render(
    <Suspense fallback={<SplashScreen />}>
        <Provider store={store}>
            <Website data={data} lang={lang} basename={basename} />
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { LoadingIndicator } from '../../../LoadingIndicator';
import styles from './styles.scss';

const Button = ({
    className,
    contentClassName,
    tag: Tag = 'button',
    disabled,
    children,
    beforeIcon,
    afterIcon,
    fullWidth,
    outline,
    rounded,
    btnStyle,
    size,
    noborder,
    nopadding,
    socialStyle,
    isFetching,
    loaderColor,
    iconFixed,
    wrapContentStyle,
    ...rest
}) => {
    const styleClassName = isFetching ? 'transparent' : outline ? btnStyle + 'Outline' : btnStyle;
    const isButton = Tag === 'button';
    const rootClassName = classNames(
        styles.root,
        className,
        noborder && styles.noborder,
        nopadding && styles.nopadding,
        styles[socialStyle],
        styles[size],
        styles[styleClassName],
        {
            [styles.fullWidth]: fullWidth,
            [styles.rounded]: rounded,
            [styles.disabled]: !isButton && (isFetching || disabled),
            [styles.iconFixedLeft]: iconFixed === 'left',
            [styles.iconFixedRight]: iconFixed === 'right',
        }
    );

    const renderIcon = (icon) => (icon ? <span className={styles.icon}>{icon}</span> : null);
    const _disabled = isFetching || disabled;
    const rootProps = {
        className: rootClassName,
        'data-element': 'button',
        ...rest,
    };
    if (isButton) rootProps['disabled'] = _disabled;

    const content = (
        <>
            {renderIcon(beforeIcon)}
            <span style={wrapContentStyle}>{children}</span>
            {renderIcon(afterIcon)}
        </>
    );

    return (
        <Tag {...rootProps}>
            <span style={{ opacity: isFetching ? 0 : 1 }} className={classNames(styles.container, contentClassName)}>
                {content}
            </span>
            {isFetching && <LoadingIndicator color={loaderColor}>{content}</LoadingIndicator>}
        </Tag>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    fullWidth: PropTypes.bool,
    afterIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    beforeIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    disabled: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.func]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
    btnStyle: PropTypes.oneOf([
        'primary',
        'primary2',
        'secondary',
        'secondary2',
        'success',
        'grey',
        'danger',
        'warning',
        'link',
        'facebook',
        'transparent',
        'white',
        'solid',
    ]),
    size: PropTypes.oneOf(['standart', 'small', 'inline', 'large']),
    iconFixed: PropTypes.oneOf(['left', 'right']),
    loaderColor: PropTypes.string,
    outline: PropTypes.bool,
    rounded: PropTypes.bool,
    noborder: PropTypes.bool,
    nopadding: PropTypes.bool,
    socialStyle: PropTypes.oneOf(['socialBtnOnly', 'socialBtnWithText']),
    socialWithText: PropTypes.bool,
    isFetching: PropTypes.bool,
    wrapContentStyle: PropTypes.object,
};

Button.defaultProps = {
    fullWidth: false,
    btnStyle: 'primary',
    outline: false,
    rounded: false,
    size: 'standart',
    disabled: false,
    wrapContentStyle: { maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' },
};

export default Button;

import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.scss';

export const LoadingIndicator = ({ color, inline, ...props }) => {
    const dotStyle = color ? { background: color } : null;

    return (
        <div className={cn(styles.spinnerContainer, inline && styles.inline)} {...props}>
            <div className={styles.loader}>
                <div className={cn(styles.dot, styles.dot1)} style={dotStyle} />
                <div className={cn(styles.dot, styles.dot2)} style={dotStyle} />
                <div className={cn(styles.dot, styles.dot3)} style={dotStyle} />
            </div>
        </div>
    );
};

LoadingIndicator.propTypes = {
    color: PropTypes.string,
    inline: PropTypes.bool,
};

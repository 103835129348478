import React from 'react';

import { cls } from '@mssgme/helpers';

import styles from './RichTextView.scss';

export const RichTextView = (props) => {
    return <div {...cls(styles.root, props)} />;
};

RichTextView.styles = styles;

import React from 'react';
import PropTypes from 'prop-types';

import { __t } from '@mssgme/shared';
import { useBrandingUrl } from '@mssgme/hooks';
import { LinkBlank } from '@mssgme/ui';
import { MssgmeLogoIcon } from '@mssgme/icons';

import styles from './BrandingMedium.scss';

export function BrandingMedium({ onModal, ...rest }) {
    const brandingUrl = useBrandingUrl({ target: 'all' });

    return (
        <div className={styles.root} {...rest}>
            <div className={styles.brandingLink}>
                <div className={styles.description}>
                    {__t('made_on')}
                    <div className={styles.logo}>
                        <MssgmeLogoIcon />
                    </div>
                    <span>mssg.me</span>
                </div>

                <LinkBlank
                    id="userpage_branding_medium"
                    data-experiment="medium"
                    href={brandingUrl}
                    // onClick={onModal}
                >
                    {__t('make_my_own')}
                </LinkBlank>
            </div>
        </div>
    );
}

BrandingMedium.propTypes = {
    onModal: PropTypes.func,
};

import PropTypes from 'prop-types';
import React from 'react';
import { FiImage } from 'react-icons/fi';

import { cls } from '@mssgme/helpers';
import styles from './ImagePreview.scss';

export function ImagePreview({ url, ...rest }) {
    return (
        <div {...cls(styles.root, rest)}>
            {url ? <img src={url} alt="preview" /> : <FiImage size={20} color="#999" />}
        </div>
    );
}

ImagePreview.propTypes = {
    url: PropTypes.string,
};

ImagePreview.defaultProps = {
    url: null,
};

import { useCallback, useRef } from 'react';

export const useScrollTo = (delay = 300) => {
    const contentRef = useRef(null);

    const scrollTo = useCallback((to, behavior = 'smooth') => {
        const container = contentRef.current;

        if (!container) {
            return;
        }

        let top = 0;

        switch (to) {
            case 'top': top = -container.scrollTop; break;
            case 'bottom': top = container.scrollHeight - container.clientHeight; break;
        }


        const timer = setTimeout(() => container.scroll({ top, behavior }), delay);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return [contentRef, scrollTo];
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useToggle } from 'react-use';
import cn from 'classnames';

import { LinkBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';

import { getPageName } from '../../lib';
import { useBlockTheme, useContrastingPageColor, useWebsiteMenu } from '../../hooks';
import { SmartButton } from '../../UI';

import styles from './PageMenu.scss';

export const PageMenu = ({
    website,
    page,
    interactive = true,
    isViewMode = false,
    scale = 1,
    onBurgerClick,
    isScaled = false,
    ...rest
}) => {
    const [isOpened, toggleOpened] = useToggle(false);
    const { enabled, items } = useWebsiteMenu(website);
    const { color, backgroundColor, isLightBG } = useContrastingPageColor();
    const { theme } = useBlockTheme({ kind: LinkBlockKind, theme: {} });
    const { style, lineStyle } = useMemo(() => {
        const style = { backgroundColor, color };
        const lineStyle = { backgroundColor: color };

        return {
            style,
            lineStyle,
        };
    }, [backgroundColor, color]);
    const homePageId = website.homePageId;
    const pageId = page._id;
    const links = useMemo(
        () => {
            const pagesMap = Object.fromEntries(
                website.pages.map((page) => [page._id, page])
            );

            return items.map(({ page: id, label, enabled }) => {
                const page = pagesMap[id];
                const isHomePage = id === homePageId;

                if (!(enabled && page)) {
                    return;
                }

                return {
                    action: { type: 'page', page: id },
                    title: label || getPageName(page, isHomePage),
                    active: id === pageId,
                };
            }).filter(Boolean);
        },
        [website.pages, items, pageId]
    );

    if (links.length <= 1 || !enabled) {
        return null;
    }

    return (
        <div className={cn(styles.wrapper, !interactive && styles.offset)} style={{ transform: isScaled ? `scale(${scale})` : 'none' }}>
            <div className={styles.contentWrapper}>
                <div id="page_menu" {...cls(styles.root, rest)}>
                    <div
                        className={cn(
                            styles.burger,
                            isOpened && styles.active,
                            !isLightBG && styles.darkerShadow
                        )}
                        style={style}
                        onClick={onBurgerClick || toggleOpened}
                    >
                        <div className={styles.burgerIcon}>
                            <div style={lineStyle} />
                            <div style={lineStyle} />
                        </div>
                    </div>

                    <nav className={cn(styles.menuContainer, isOpened && styles.active)}>
                        <div className={styles.menuBackground} style={{ ...style, transform: isOpened ? `scale(${window.innerHeight / 56 * 2})` : 'none' }} />
                        <ul className={styles.menuList} style={style} key={style.color}>
                            {links.map((link) => (
                                <li key={link.action.page} className={styles.menuListItem}>
                                    <div
                                        className={cn(styles.menuLink, link.active && styles.active)}
                                        onClick={toggleOpened}
                                    >
                                        <SmartButton
                                            interactive={interactive}
                                            isViewMode={isViewMode}
                                            id={link.action.page}
                                            link={link}
                                            active={link.active}
                                            width={theme.width}
                                            icon={theme.icon}
                                            urlSubtitle={theme.urlSubtitle}
                                            style={style}
                                            className={styles.menuButton}
                                        />

                                        <div className={styles.activeMark} style={lineStyle} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

PageMenu.propTypes = {
    website: LandingsPropTypes.website.isRequired,
    page: LandingsPropTypes.page.isRequired,
    interactive: PropTypes.bool,
    isViewMode: PropTypes.bool,
    scale: PropTypes.number,
    onBurgerClick: PropTypes.func,
    isScaled: PropTypes.bool,
};

import React from 'react';
import { generatePath } from 'react-router';

import { resolvePathname } from '@mssgme/helpers';

export const makeCoreRouter = ({ pageSlug = '', path }) => {
    const toAbsolutePath = (path, { pageSlug: slug, ...params } = {}) => {
        // `generatePath` throws on empty path segments, and '/' is converted to '%2F'
        const base = path.replace(':pageSlug', slug ?? pageSlug);

        return resolvePathname(
            generatePath(base, Object.fromEntries(Object.entries(params).map(([param, value]) => [
                param,
                String(value ?? ''),
            ])))
        );
    };
    const base = toAbsolutePath(path);
    const entry = base || '/';

    return {
        toEntry: () => entry,
        toHome: () => entry,
        toPage: (pageSlug) => toAbsolutePath(path, { pageSlug }),
        product: {
            toView: (productSlug) => toAbsolutePath(`${base}/p/:productSlug`, {
                productSlug: productSlug.toLowerCase(),
            }),
        },
        collection: {
            toView: (collectionSlug) => toAbsolutePath(`${base}/c/:collectionSlug`, {
                collectionSlug: collectionSlug.toLowerCase(),
            }),
        },
        orders: {
            toList: () => toAbsolutePath(`${base}/orders`),
            toView: (orderId, basePath) => toAbsolutePath(`${basePath || base}/orders/:orderId`, {
                orderId,
            }),
            toStatus: (orderId, status) => toAbsolutePath(`${base}/orders/:orderId/payment/:status`, {
                orderId,
                status,
            }),
        },
    };
};

export const CoreContext = React.createContext({
    path: '/',
    router: makeCoreRouter({ path: '/' }),
});

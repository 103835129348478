import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TinyColor2 from 'tinycolor2';

import { __t } from '@mssgme/shared';
import { useBrandingUrl } from '@mssgme/hooks';
import { LinkBlank } from '@mssgme/ui';
import { MssgmeLogoIcon, ArrowRightIcon } from '@mssgme/icons';

import styles from './BrandingMinimalist.scss';

export function BrandingMinimalist({ onModal, resolvedTheme = {}, ...rest }) {
    const brandingUrl = useBrandingUrl({ target: 'all' });
    const style = useMemo(() => resolvedTheme.colors
        ? {
            border: `1px solid ${TinyColor2(resolvedTheme.colors.text).setAlpha(0.2).toHex8String()}`,
            color: resolvedTheme.colors.text,
        }
        : {}, [resolvedTheme]);

    return (
        <div className={styles.root} {...rest}>
            <LinkBlank
                className={styles.brandingLink}
                id="userpage_branding_minimalist"
                data-experiment="minimalist"
                href={brandingUrl}
                style={style}
            >
                <MssgmeLogoIcon className={styles.logo} />
                <span>{__t('make_for_free')}</span>
                <ArrowRightIcon className={styles.arrowIcon}/>
            </LinkBlank>
        </div>
    );
}

BrandingMinimalist.propTypes = {
    resolvedTheme: PropTypes.object,
    onModal: PropTypes.func,
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useMeasure } from 'react-use';

import { CustomBlockKind } from '@mssgme/shared';
import { cls } from '@mssgme/helpers';
import { LandingsPropTypes } from '@mssgme/ui';
import { useBlockTheme, usePageData, useRenderer } from '../../../hooks';
import { BlockBase } from '../BlockBase';
import { mapGridArea } from '../gridUtils';

import styles from './Custom.scss';

const mapBlocks = (blocks, items) => {
    const map = new Map();

    items.map((id) => id && map.set(id, blocks.find(({ _id }) => _id === id)));

    return (id) => map.get(id);
};
const identityInsets = {
    margin: 4,
    padding: 4,
};

export default function Custom({ block, style: baseStyle, renderItem, ...rest }) {
    const [ref, { width }] = useMeasure();
    const { style } = useBlockTheme(block);
    const renderBlock = useRenderer();
    const page = usePageData();
    const findBlock = useMemo(() => mapBlocks(page.blocks, block.areas.map(({ blockId }) => blockId)), [
        page.blocks,
        block.areas,
    ]);
    const dx = width / 12;
    const combined = useMemo(() => ({ gridTemplateRows: 'repeat(12, auto)', ...style, ...baseStyle }), [
        dx,
        style,
        baseStyle,
    ]);

    const render = ({ blockId, area }, index) => {
        const block = blockId && findBlock(blockId);
        const template = block && renderBlock(block, index);
        const node =
            template &&
            React.cloneElement(template, {
                style: { gridArea: mapGridArea(area), zIndex: index },
                className: styles.element,
                insets: identityInsets,
                interactive: rest.interactive,
                sortable: rest.sortable,
                embedded: false,
            });

        if (block) {
            if (renderItem) {
                return renderItem({ block, area, index, node });
            }

            if (node) {
                return node;
            }
        }

        return <div key={index} />;
    };

    return (
        <BlockBase ref={ref} style={combined} {...cls(styles.root, rest)}>
            {block.areas.map(render)}
        </BlockBase>
    );
}

Custom.propTypes = {
    ...BlockBase.propTypes,
    block: LandingsPropTypes.block.isRequired,
    renderItem: PropTypes.func,
};

Custom.defaultProps = {
    ...BlockBase.defaultProps,
    insets: { margin: 8, padding: 0 },
};

Custom.kind = CustomBlockKind;

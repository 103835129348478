import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { EventEmitter } from 'events';

export const createSynchronizedStorage = () => {
    const LS_EVENT = 'ls_changed';
    const ee = new EventEmitter();
    const identityTransform = {
        parse: (v) => v,
        stringify: (v) => v,
    };
    const emitChange = () => ee.emit(LS_EVENT);

    ee.setMaxListeners(100);

    const useStorageItem = (key, initialValue, { transform = identityTransform } = {}) => {
        const initializer = useRef((key) => {
            try {
                const value = localStorage.getItem(key);

                if (value !== null) {
                    return transform.parse(value);
                } else {
                    initialValue && localStorage.setItem(key, transform.stringify(initialValue));
                }
            } catch (e) {
                //
            }

            return initialValue;
        });
        const [state, setState] = useState(() => initializer.current(key));

        useLayoutEffect(() => {
            const handler = () => {
                setState(initializer.current(key));
            };

            handler();
            ee.on(LS_EVENT, handler);

            return () => {
                ee.off(LS_EVENT, handler);
            };
        }, [key]);

        const set = useCallback(
            (valOrFunc) => {
                try {
                    const newState = typeof valOrFunc === 'function' ? valOrFunc(state) : valOrFunc;
                    const value = transform.stringify(newState);

                    localStorage.setItem(key, value);
                    setState(transform.parse(value));
                } catch (e) {
                    //
                }
            },
            [key, setState]
        );

        return [state, set];
    };

    const useBatchUpdate = (fn) => {
        const ref = useRef(fn);

        ref.current = fn;

        return useCallback((...args) => {
            try {
                return ref.current(...args);
            } finally {
                emitChange();
            }
        }, []);
    };

    return { useStorageItem, useBatchUpdate, emitChange };
};
